import React, { Component } from "react";

//CSS
import ImgTextoCSS from "../../assets/css/Web/ImgTexto.module.css";

//IMG
import bienvenida_1 from "./../../assets/img/bienvenida/home1.webp";
import bienvenida_2 from "./../../assets/img/bienvenida/home2.webp";
import bienvenida_3 from "./../../assets/img/bienvenida/home3.webp";
import bienvenida_4 from "./../../assets/img/bienvenida/home4.webp";
import bienvenida_5 from "./../../assets/img/bienvenida/home5.webp";
import bienvenida_6 from "./../../assets/img/bienvenida/home6.webp";
import bienvenida_7 from "./../../assets/img/bienvenida/home7.webp";
import bienvenida_8 from "./../../assets/img/bienvenida/home8.webp";
import next from "../../assets/img/carrucel/next.svg";

export default class ImagenConTexto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indiceActual: 0,
    };

    this.colores = [
      { max: "#1d93b2", min: "#33b4d3" },
      { max: "#c6406d", min: "#e27695" },
      { max: "#00759e", min: "#2798d8" },
      { max: "#b25900", min: "#d16f05" },
      { max: "#064d8e", min: "#1a5faa" },
      { max: "#197572", min: "#289499" },
      { max: "#856aa5", min: "#b093cc" },
      { max: "#63934d", min: "#93c67d" },
    ];
    this.ultimaActualizacion = performance.now();
    this.frameId = null;
  }

  componentDidMount() {
    this.precargaImagen();
    this.animar();
  }
  componentWillUnmount() {
    // Cancelar la animación al desmontar el componente
    cancelAnimationFrame(this.frameId);
  }

  animar = () => {
    const tiempoPorSlide = 7000;
    const ahora = performance.now();

    if (ahora - this.ultimaActualizacion >= tiempoPorSlide) {
      this.cambiarSlide();
      this.ultimaActualizacion = ahora;
    }

    this.frameId = requestAnimationFrame(this.animar);
  };

  cambiarSlide = () => {
    const slides = [bienvenida_1, bienvenida_2, bienvenida_3, bienvenida_4, bienvenida_5, bienvenida_6, bienvenida_7, bienvenida_8];
    this.setState((prevState) => ({
      indiceActual: (prevState.indiceActual + 1) % slides.length,
    }));
  };
  cambiarSlideManual = (accion) => {
    const slides = [bienvenida_1, bienvenida_2, bienvenida_3, bienvenida_4, bienvenida_5, bienvenida_6, bienvenida_7, bienvenida_8];
    const totalSlides = slides.length;

    this.setState((prevState) => {
      let nuevoIndice;

      if (accion === "next") {
        nuevoIndice = (prevState.indiceActual + 1) % totalSlides;
      } else if (accion === "back") {
        nuevoIndice = (prevState.indiceActual - 1 + totalSlides) % totalSlides;
      }

      // Reiniciar el tiempo para que animar no se active antes de los 10 segundos
      this.ultimaActualizacion = performance.now();
      return { indiceActual: nuevoIndice };
    });
    this.frameId = requestAnimationFrame(this.animar);
  };

  precargaImagen = () => {
    const imagenes = [bienvenida_1, bienvenida_2, bienvenida_3, bienvenida_4, bienvenida_5, bienvenida_6, bienvenida_7, bienvenida_8];
    return imagenes.map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });
  };

  llamarScroll = (nav) => {
    this.props.scroll(nav);
  };

  componentWillUnmount() {
    clearTimeout(this.slideTimer);
  }

  render() {
    const { indiceActual } = this.state;
    const tiempoTransicion = 2000;
    const slides = [bienvenida_1, bienvenida_2, bienvenida_3, bienvenida_4, bienvenida_5, bienvenida_6, bienvenida_7, bienvenida_8];

    const cnt_slide = [
      <div className={ImgTextoCSS.cntSlide} id={1}>
        <img loading="eager" alt="bienvenida1" src={bienvenida_1} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada1}>
          <p className={ImgTextoCSS.txtPortada1}>
            Reserva tus horas, gestiona e infórmate <br />
            todo en la palma de tu mano.
          </p>
          <button onClick={() => this.props.scroll(5)}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={2}>
        <img loading="eager" alt="bienvenida2" src={bienvenida_2} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada2}>
          <p className={ImgTextoCSS.txtPortada2}>
            Con Horasalud no pierdas tiempo,
            <br />
            en caso de suspensión, reagenda y contacta
            <br />
            inmediatamente a tus usuarios.
          </p>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={3}>
        <img loading="eager" alt="bienvenida2" src={bienvenida_3} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada3}>
          <p className={ImgTextoCSS.txtPortada3}>
            Que el espacio o el lugar no limiten tu trabajo...
            <br />
            Atiende y controla a más pacientes
            <br />
            con nuestra Telemedicina cifrada y segura.
          </p>
          <button onClick={() => this.props.scroll(5)}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide} `} id={4}>
        <img loading="eager" alt="bienvenida4" src={bienvenida_4} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada4}>
          <p className={ImgTextoCSS.txtPortada4}>
            Somos Empresa B certificada <br />
            hace más de 10 años, creando impacto social, <br />
            económico y medioambiental positivo.
          </p>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide} `} id={5}>
        <img loading="eager" alt="bienvenida5" src={bienvenida_5} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada5}>
          <p className={ImgTextoCSS.txtPortada5}>
            Prioriza y has más ágiles tus tiempos de espera, <br />
            respetando la ley de atención preferente y <br />
            aprovechando los tiempos de espera.
          </p>
          <button onClick={() => this.props.scroll(5)}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide} `} id={6}>
        <img loading="eager" alt="bienvenida5" src={bienvenida_6} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada6}>
          <p className={ImgTextoCSS.txtPortada6}>Volvamos a hacer comunidad.</p>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide} `} id={7}>
        <img loading="eager" alt="bienvenida5" src={bienvenida_7} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada7}>
          <p className={ImgTextoCSS.txtPortada7}>
            Con HoraSalud optimiza tus tareas y <br /> reduce tu carga de trabajo.
          </p>
          <button onClick={() => this.props.scroll(5)}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide} `} id={8}>
        <img loading="eager" alt="bienvenida5" src={bienvenida_8} className={ImgTextoCSS.imgBienvenida} />
        <div className={ImgTextoCSS.ctnPortada8}>
          <p className={ImgTextoCSS.txtPortada8}>
            Somos tu mejor compañia, <br /> con nuestras herramientas IA llega a tus metas.
          </p>
        </div>
      </div>,
    ];

    return (
      <>
        <div className={ImgTextoCSS.imgPrincipal}>
          {slides.map((slide, index) => {
            const esActual = indiceActual === index;
            const esSiguiente = indiceActual === (index - 1 + slides.length) % slides.length;
            const esAnterior = indiceActual === (index + 1) % slides.length;

            return (
              <div
                key={index}
                className="slide"
                style={{
                  position: "absolute",
                  width: "100%",
                  background: `linear-gradient(to left, ${this.colores[index].min} 53%, ${this.colores[index].max} 73%)`,
                  height: "100%",
                  opacity: esActual ? 1 : 0,
                  transform: esActual
                    ? "translateX(0)"
                    : esSiguiente
                    ? "translateX(100%)" // Mueve el siguiente slide fuera de la vista (a la derecha)
                    : esAnterior
                    ? "translateX(-100%)" // Mueve el anterior slide fuera de la vista (a la izquierda)
                    : "translateX(0)",
                  transition: `transform ${tiempoTransicion}ms ease, opacity ${tiempoTransicion}ms ease`,
                  zIndex: esActual ? 8 : 0,
                }}>
                {cnt_slide[index]}
                <img src={next} onClick={() => this.cambiarSlideManual("back")} className={ImgTextoCSS.siguiente} />
                <img src={next} onClick={() => this.cambiarSlideManual("next")} className={ImgTextoCSS.anterior} />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
