import React, { Component } from "react";

//CSS
import InvestigacionCSS from "../../assets/css/Web/investigacion.module.css";

export default class Nosotros extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={InvestigacionCSS.cntPrincipalMision}>
          <div className={InvestigacionCSS.cntNosotros}>
            <div className={InvestigacionCSS.cntTexto}>
              <h2 className={InvestigacionCSS.titulo}>Nuestra misión</h2>
              <div className={InvestigacionCSS.cntTxtNosotros}>
                <p className={InvestigacionCSS.cntParrafo}>
                  En HoraSalud llevamos más de una década entregando herramientas para la salud pública
                  <br />
                  ayudando en la gestión, contactabilidad y toma de decisiones estratégicas, <br />
                  todo gracias a nuestra plataforma con motor de inteligencia artificial VICOS. <br />
                  Hoy con más de 12 años de experiencia podemos ayudar a optimizar la atención y <br />
                  la gestión en los centros de salud, haciéndolos más accesibles, eficientes y amigables.
                  <br />
                </p>
                <p className={InvestigacionCSS.cntParrafo}>
                  Como Empresa B certificada, estamos comprometidos con generar un impacto positivo, <br />
                  no solo en la salud, sino también en nuestra sociedad y medioambiente.
                  <br />
                  <br /> Nuestra calidad y compromiso nos respaldan, porque el saber escuchar, <br />
                  nos permite hacer las cosas bien.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
