import React, { Component } from "react";
import UpperbarCss from "../../assets/css/Web/upperbar.module.css";

// IMG
import horasalud from "../../assets/img/horaSalud.svg";

export default class Upperbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={UpperbarCss.upperArea}>
        <div className={UpperbarCss.logo_hs}>
          <img alt="Logo HoraSalud" src={horasalud} style={{ width: "100%" }} />
        </div>

        <div className={UpperbarCss.cntBotonesUppbar}>
          <div className={UpperbarCss.opacidadUppbar}></div>
          <a className={`${UpperbarCss.btnUpperBar} ${UpperbarCss.seleccionado} `} onClick={() => this.props.scroll(4)}>
            Misión
          </a>
          <a className={`${UpperbarCss.btnUpperBar} ${UpperbarCss.seleccionado} `} onClick={() => this.props.scroll(2)}>
            Productos
          </a>
          <a className={`${UpperbarCss.btnUpperBar} ${UpperbarCss.seleccionado} `} onClick={() => this.props.scroll(5)}>
            Contacto
          </a>
          <a className={`${UpperbarCss.btnUpperBar} ${UpperbarCss.seleccionado} `} onClick={() => this.props.cambiarEstado(true)}>
            Accesos
          </a>
        </div>
      </div>
    );
  }
}
