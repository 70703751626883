import React, { Component } from "react";

//IMG
import bienvenida1 from "./../../assets/img/mobile/home-mobile.webp";
import bienvenida2 from "./../../assets/img/mobile/home-mobile2.webp";
import bienvenida3 from "./../../assets/img/mobile/home-mobile3.webp";
import bienvenida4 from "./../../assets/img/mobile/home-mobile4.webp";
import bienvenida5 from "./../../assets/img/mobile/home-mobile5.webp";
import bienvenida6 from "./../../assets/img/mobile/home-mobile6.webp";
import bienvenida7 from "./../../assets/img/mobile/home-mobile7.webp";
import bienvenida8 from "./../../assets/img/mobile/home-mobile8.webp";
import next from "../../assets/img/carrucel/next.svg";

import logoHs from "./../../assets/img/logoHsB.svg";
import menu from "./../../assets/img/mobile/menu.svg";

//CSS
import ImgTextoCSS from "../../assets/css/Mobile/ImgTexto.module.css";
export default class ImagenConTexto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indiceActual: 0,
    };
    this.ultimaActualizacion = performance.now();
    this.frameId = null;
  }

  componentDidMount() {
    this.precargaImagen();
    this.animar();
  }

  componentWillUnmount() {
    // Cancelar la animación al desmontar el componente
    cancelAnimationFrame(this.frameId);
  }

  animar = () => {
    const tiempoPorSlide = 7000;
    const ahora = performance.now();

    if (ahora - this.ultimaActualizacion >= tiempoPorSlide) {
      this.cambiarSlide();
      this.ultimaActualizacion = ahora;
    }

    this.frameId = requestAnimationFrame(this.animar);
  };

  cambiarSlide = () => {
    const slides = [bienvenida1, bienvenida2, bienvenida3, bienvenida4, bienvenida5, bienvenida6, bienvenida7, bienvenida8];
    this.setState((prevState) => ({
      indiceActual: (prevState.indiceActual + 1) % slides.length,
    }));
  };
  cambiarSlideManual = (accion) => {
    const slides = [bienvenida1, bienvenida2, bienvenida3, bienvenida4, bienvenida5, bienvenida6, bienvenida7, bienvenida8];
    const totalSlides = slides.length;

    this.setState((prevState) => {
      let nuevoIndice;

      if (accion === "next") {
        nuevoIndice = (prevState.indiceActual + 1) % totalSlides;
      } else if (accion === "back") {
        nuevoIndice = (prevState.indiceActual - 1 + totalSlides) % totalSlides;
      }

      // Reiniciar el tiempo para que animar no se active antes de los 10 segundos
      this.ultimaActualizacion = performance.now();
      return { indiceActual: nuevoIndice };
    });
    this.frameId = requestAnimationFrame(this.animar);
  };

  precargaImagen = () => {
    const imagenes = [bienvenida1, bienvenida2, bienvenida3, bienvenida4, bienvenida5, bienvenida6, bienvenida7, bienvenida8];
    return imagenes.map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });
  };

  componentWillUnmount() {
    clearTimeout(this.slideTimer);
  }

  cambiarEstado = () => {
    this.props.cambiarEstado();
    setTimeout(() => {
      this.props.scroll(5);
    }, 300);
  };

  render() {
    const { indiceActual } = this.state;
    const tiempoTransicion = 2000;
    const slides = [bienvenida1, bienvenida2, bienvenida3, bienvenida4, bienvenida5, bienvenida6, bienvenida7, bienvenida8];

    const cnt_slide = [
      <div className={`${ImgTextoCSS.cntSlide}`} id={1}>
        <img alt="imagen de bienvenida" loading="eager" src={bienvenida1} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ margin: 0 }}>
            Reserva tus horas, gestiona e infórmate <br />
            todo en la palma de tu mano.
          </p>
          <button onClick={() => this.cambiarEstado()}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={2}>
        <img alt="imagen de bienvenida 2" loading="eager" src={bienvenida2} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ margin: 0 }}>
            Con Horasalud no pierdas tiempo, <br />
            en caso de suspensión, <br />
            reagenda y contacta inmediatamente <br />a tus usuarios.
          </p>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={3}>
        <img alt="imagen de bienvenida 3" loading="eager" src={bienvenida3} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto3}>
          <p style={{ margin: 0 }}>
            Que el espacio o el lugar <br />
            no limiten tu trabajo... <br />
            Atiende y controla a más pacientes <br />
            con nuestra Telemedicina <br />
            cifrada y segura.
          </p>
          <button onClick={() => this.cambiarEstado()}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={4}>
        <img alt="imagen de bienvenida 2" loading="eager" src={bienvenida4} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ margin: 0 }}>
            Somos Empresa B certificada <br />
            hace más de 10 años <br />
            creando impacto social, <br />
            económico y medioambiental positivo.
          </p>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={5}>
        <img alt="imagen de bienvenida 2" loading="eager" src={bienvenida5} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ margin: 0 }}>
            Prioriza y has más ágiles <br />
            tus tiempos de espera,
            <br />
            respetando la ley de atención preferente
            <br />y aprovechando los tiempos de espera.
          </p>
          <button onClick={() => this.cambiarEstado()}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={6}>
        <img alt="imagen de bienvenida 2" loading="eager" src={bienvenida6} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ fontSize: "5vw" }}>Volvamos a hacer comunidad.</p>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={7}>
        <img alt="imagen de bienvenida 2" loading="eager" src={bienvenida7} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ fontSize: "5vw" }}>
            Con HoraSalud optimiza tus tareas y <br /> reduce tu carga de trabajo.
          </p>
          <button onClick={() => this.cambiarEstado()} style={{ margin: 0 }}>
            ¡Contáctanos y <br />
            conoce más!
          </button>
        </div>
      </div>,

      <div className={`${ImgTextoCSS.cntSlide}`} id={8}>
        <img alt="imagen de bienvenida 2" loading="eager" src={bienvenida8} className={ImgTextoCSS.imgBienvenida} />

        <div className={ImgTextoCSS.cntTexto1}>
          <p style={{ fontSize: "5vw" }}>
            Somos tu mejor compañia, <br /> con nuestras herramientas IA llega a tus metas.
          </p>
        </div>
      </div>,
    ];
    return (
      <>
        <div className={ImgTextoCSS.imgPrincipal}>
          <img src={menu} className={ImgTextoCSS.menu} onClick={() => this.props.abrirMenu()} />
          <img src={logoHs} className={ImgTextoCSS.logohs} />

          {slides.map((slide, index) => {
            const esActual = indiceActual === index;
            const esSiguiente = indiceActual === (index - 1 + slides.length) % slides.length;
            const esAnterior = indiceActual === (index + 1) % slides.length;

            return (
              <div
                key={index}
                className="slide"
                style={{
                  position: esActual ? "relative" : "absolute",
                  width: "100%",
                  opacity: esActual ? 1 : 0,
                  transform: esActual
                    ? "translateX(0)"
                    : esSiguiente
                    ? "translateX(100%)" // Mueve el siguiente slide fuera de la vista (a la derecha)
                    : esAnterior
                    ? "translateX(-100%)" // Mueve el anterior slide fuera de la vista (a la izquierda)
                    : "translateX(0)",
                  transition: `transform ${tiempoTransicion}ms ease, opacity ${tiempoTransicion}ms ease`,
                  zIndex: esActual ? 8 : 0,
                }}>
                {cnt_slide[index]}
                <img src={next} onClick={() => this.cambiarSlideManual("back")} className={ImgTextoCSS.siguiente} />
                <img src={next} onClick={() => this.cambiarSlideManual("next")} className={ImgTextoCSS.anterior} />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
