import React, { Component, createRef } from "react";

//JS
import Upperbar from "../../components/Web/Upperbar";
import Opiniones from "../../components/Web//Opiniones";
import ImagenTexto from "../../components/Web//ImagenTexto";
import Imagen3Textos from "../../components/Web/Imagen3Texto";
import Footer from "./../../components/Web/Footer";
import Carrucel from "../../components/Web/Carrucel";
import Nosotros from "../../components/Web/Nosotros";
import Contactanos from "../../components/Web/Contactanos";
import Annios from "../../components/Web/BannerAnnios";

//IMG
import salucin_chat from "../../assets/img/salusin-chat.png";
import "../../assets/css/general.css";

//CSS
import CarrucelCSS from "../../assets/css/Web/carrucel.module.css";

export default class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accesos: false,
    };

    // Crear referencias para cada sección
    this.section1Ref = createRef();
    this.section2Ref = createRef();
    this.section3Ref = createRef();
    this.section4Ref = createRef();
    this.section5Ref = createRef();
  }

  scrollToSection = (sectionNumber) => {
    let ref;
    switch (sectionNumber) {
      case 1:
        ref = this.section1Ref;
        break;
      case 2:
        ref = this.section2Ref;
        break;
      case 3:
        ref = this.section3Ref;
        break;
      case 4:
        ref = this.section4Ref;
        break;
      case 5:
        ref = this.section5Ref;
        break;
      default:
        return;
    }

    // Comprobar si la referencia existe antes de intentar usarla
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  cambiarEstado = (estado) => [this.setState({ accesos: estado })];

  render() {
    const { accesos } = this.state;
    return (
      <>
        <div>
          {accesos ? (
            <>
              <Imagen3Textos cambiarEstado={this.cambiarEstado} />
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Upperbar scroll={this.scrollToSection} cambiarEstado={this.cambiarEstado} />
              </div>

              <div ref={this.section1Ref} style={{ display: "flex", justifyContent: "flex-end" }}>
                <ImagenTexto scroll={this.scrollToSection} />
              </div>

              <div ref={this.section4Ref} style={{ zIndex: "99999", background: "white", position: "relative" }}>
                <Nosotros />
              </div>

              <div ref={this.section2Ref} className={CarrucelCSS.cntCarrucelJs}>
                <Carrucel />
              </div>

              <div>
                <Opiniones />
              </div>

              <div>
                <Annios />
              </div>

              <div ref={this.section5Ref}>
                <Contactanos />
              </div>

              <Footer scroll={this.scrollToSection} />
            </>
          )}
        </div>
      </>
    );
  }
}
