import React, { Component } from "react";

//CSS
import Imagen3TextoCSS from "../../assets/css/Web/Imagen3TextoCSS.module.css";

//IMG
import btn_acceso from "../../assets/img/accesos/btnAccesos.svg";

import btn_funcionario from "../../assets/img/accesos/btnFuncionario.svg";
import btn_funcionario_activo from "../../assets/img/accesos/btnFuncionarioPActivo.svg";

import btn_salud from "../../assets/img/accesos/btnSalud.svg";
import btn_salud_activo from "../../assets/img/accesos/btnSaludPActivo.svg";

import btn_social from "../../assets/img/accesos/btnSocial.svg";
import btn_social_activo from "../../assets/img/accesos/btnSocialPActivo.svg";

import btn_teleconsulta from "../../assets/img/accesos/btnTeleconsulta.svg";
import btn_teleconsulta_activo from "../../assets/img/accesos/btnTeleconsultaPActivo.svg";

import btn_usuario from "../../assets/img/accesos/btnUsuarios.svg";
import btn_usuario_activo from "../../assets/img/accesos/btnUsuariosPActivo.svg";

import btn_funcionario_mobile from "../../assets/img/mobile/btnFuncionarios.svg";
import btn_salud_mobile from "../../assets/img/mobile/btnSalud.svg";
import btn_teleconsulta_mobile from "../../assets/img/mobile/btnTeleconsulta.svg";
import btn_usuario_mobile from "../../assets/img/mobile/btnUsuario.svg";
import btn_social_mobile from "../../assets/img/mobile/social.svg";

export default class Imagen3Texto extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className={Imagen3TextoCSS.divPrincipalWeb}>
          <div onClick={() => this.props.cambiarEstado(false)} style={{ position: "absolute", width: "100%", height: "100%" }}></div>
          <img src={btn_acceso} className={Imagen3TextoCSS.accesos} />
          <a href="https://horasalud.cl/web/portal/" target="_blank">
            <img src={btn_usuario} className={Imagen3TextoCSS.btn_usuario} />
            <img src={btn_usuario_activo} className={Imagen3TextoCSS.btn_usuario_activo} />
          </a>

          <a href="https://panelv2hs.cl/salud/" target="_blank">
            <img src={btn_salud} className={Imagen3TextoCSS.btn_salud} />
            <img src={btn_salud_activo} className={Imagen3TextoCSS.btn_salud_activo} />
          </a>

          <a href="https://panelv2hs.cl/" target="_blank">
            <img src={btn_funcionario} className={Imagen3TextoCSS.btn_funcionario} />
            <img src={btn_funcionario_activo} className={Imagen3TextoCSS.btn_funcionario_activo} />
          </a>

          <a href="https://panelv2hs.cl/web_medico/" target="_blank">
            <img src={btn_teleconsulta} className={Imagen3TextoCSS.btn_teleconsulta} />
            <img src={btn_teleconsulta_activo} className={Imagen3TextoCSS.btn_teleconsulta_activo} />
          </a>

          <a href="https://panelv2hs.cl/departamento/" target="_blank">
            <img src={btn_social} className={Imagen3TextoCSS.btn_social} />
            <img src={btn_social_activo} className={Imagen3TextoCSS.btn_social_activo} />
          </a>
        </div>

        <div className={Imagen3TextoCSS.divPrincipalMobile}>
          <span onClick={() => this.props.cambiarEstado(false)} className={Imagen3TextoCSS.cerrar}>
            X
          </span>
          <a href="https://horasalud.cl/web/portal/" target="_blank">
            <img src={btn_usuario_mobile} className={Imagen3TextoCSS.btn_usuario} />
          </a>
          <a href="https://panelv2hs.cl/salud/" target="_blank">
            <img src={btn_salud_mobile} className={Imagen3TextoCSS.btn_salud} />
          </a>

          <a href="https://panelv2hs.cl/" target="_blank">
            <img src={btn_funcionario_mobile} className={Imagen3TextoCSS.btn_funcionario} />
          </a>

          <a href="https://panelv2hs.cl/web_medico/" target="_blank">
            <img src={btn_teleconsulta_mobile} className={Imagen3TextoCSS.btn_teleconsulta} />
          </a>

          <a href="https://panelv2hs.cl/departamento/" target="_blank">
            <img src={btn_social_mobile} className={Imagen3TextoCSS.btn_social} />
          </a>
        </div>
      </>
    );
  }
}
