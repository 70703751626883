import React, { Component, createRef } from "react";

//JS
import Carrucel from "../../components/Mobile/Carrucel";
import ImagenTexto from "../../components/Mobile/ImagenTexto";
import Accesos from "../../components/Mobile/Accesos";
import Slide from "../../components/Mobile/slide/slide";
import Footer from "../../components/Mobile/Footer";
import Nosotros from "../../components/Mobile/Nosotros";
import Opiniones from "../../components/Mobile/Opiniones";
import Contacto from "../../components/Mobile/Contactanos";
import Annios from "../../components/Mobile/BannerAnnios";
import Productos from "../../components/Mobile/Productos";

//CSS
import "../../assets/css/general.css";

export default class Inicio extends Component {
  constructor() {
    super();

    this.state = {
      navVisible: false,
      sidebar: false,
      detalleProductos: false,
      accesos: false,
    };
    // Crear referencias para cada sección
    this.section1Ref = createRef();
    this.section2Ref = createRef();
    this.section3Ref = createRef();
    this.section4Ref = createRef();
    this.section5Ref = createRef();
  }

  scrollToSection = (sectionNumber) => {
    let ref;
    switch (sectionNumber) {
      case 1:
        ref = this.section1Ref;
        break;
      case 2:
        ref = this.section2Ref;
        break;
      case 3:
        ref = this.section3Ref;
        break;
      case 4:
        ref = this.section4Ref;
        break;
      case 5:
        ref = this.section5Ref;
        break;
      default:
        return;
    }

    // Comprobar si la referencia existe antes de intentar usarla
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  mostrarSlide = () => {
    this.setState({ sidebar: !this.state.sidebar });
  };

  ocultarSlide = () => {
    this.setState({ sidebar: false });
  };

  cambiarEstadoProducto = (estado) => {
    this.setState({ detalleProductos: estado });
  };

  cambiarEstado = (estado) => {
    console.log(estado);

    this.setState({ accesos: estado });
  };

  render() {
    const { detalleProductos, accesos } = this.state;
    return (
      <>
        <div style={{ overflowY: "scroll", height: "100vh" }}>
          {accesos ? (
            <div>
              <Accesos cambiarEstado={this.cambiarEstado} />
            </div>
          ) : (
            <>
              <Slide
                slide={this.state.sidebar}
                cambiarEstado={() => this.cambiarEstadoProducto(false)}
                ocultarSlide={() => this.ocultarSlide()}
                scroll={this.scrollToSection}
                estadoAcceso={this.cambiarEstado}
              />
              <div id="contenedor" className="container">
                <div ref={this.section1Ref}>
                  <ImagenTexto
                    cambiarEstado={() => this.cambiarEstadoProducto(false)}
                    abrirMenu={() => this.mostrarSlide()}
                    scroll={this.scrollToSection}
                  />
                </div>

                {detalleProductos ? (
                  <div ref={this.section2Ref}>
                    <Carrucel cambiarEstado={() => this.cambiarEstadoProducto(false)} scroll={this.scrollToSection} />
                  </div>
                ) : (
                  <>
                    <div ref={this.section4Ref}>
                      <Nosotros />
                    </div>

                    <div ref={this.section2Ref}>
                      <Productos cambiarEstado={() => this.cambiarEstadoProducto(true)} scroll={this.scrollToSection} />
                    </div>

                    <div>
                      <Opiniones />
                    </div>

                    <div>
                      <Annios />
                    </div>

                    <div ref={this.section5Ref}>
                      <Contacto />
                    </div>
                  </>
                )}
                <div>
                  <Footer scroll={this.scrollToSection} />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
