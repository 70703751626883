import React, { Component } from "react";

//CSS
import NosotrosCSS from "../../assets/css/Mobile/Nosotros.module.css";

//IMG
import fdo_nosotros from "../../assets/img/mobile/nosotros.webp";

export default class Investigacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false,
    };
  }
  mostrarSlide = () => {
    this.setState({ sidebar: true });
  };
  ocultarSlide = () => {
    this.setState({ sidebar: false });
  };

  render() {
    return (
      <>
        <div style={{ position: "relative", color: "white" }}>
          <img src={fdo_nosotros} className={NosotrosCSS.fdoNosotros} />
          <h2 className={NosotrosCSS.titulo}>Nuestra misión</h2>
          <p className={NosotrosCSS.cntParrafo}>
            En HoraSalud llevamos más de una década entregando herramientas para la salud pública ayudando en la gestión, contactabilidad y <br />
            toma de decisiones estratégicas, todo gracias <br /> a nuestra plataforma con motor de <br />
            inteligencia artificial VICOS. Hoy con más <br />
            de 12 años de experiencia podemos ayudar
            <br /> a optimizar la atención y la gestión en los centros <br />
            de salud, haciéndolos más accesibles, <br />
            eficientes y amigables.
          </p>
          <p className={NosotrosCSS.cntParrafo2}>
            Como Empresa B certificada,
            <br /> estamos comprometidos con generar <br />
            un impacto positivo, no solo en la salud, <br />
            sino también en nuestra sociedad y medioambiente.
            <br />
            <br /> Nuestra calidad y compromiso nos respaldan, porque el saber escuchar,
            <br /> nos permite hacer las cosas bien.
          </p>
        </div>
      </>
    );
  }
}
