import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "./../../config";

//Componentes
import chileanRut from "chilean-rut";

//IMG
import camioneta from "./../../assets/img/Contacto/contacto.webp";
import camionetaMobile from "./../../assets/img/mobile/camionetaMobileWeb.webp";
import qr from "./../../assets/img/mobile/qrs.png";

//CSS
import "../../assets/css/general.css";
import ContactanosCSS from "../../assets/css/Web/contactanos.module.css";

export default class Contactanos extends Component {
  constructor() {
    super();
    this.state = {
      // ARREGLOS Y DICCIONARIOS
      msjValidacion: {},
      ArrComunas: [],
      ArrRegion: [],

      // VALORES STRING
      nombre: "",
      correo: "",
      telefono: "",
      rut: "",
      comuna: "",
      mensaje: "",
      ver_rut: "",
      mensajeExiste: "",
      cesfam: "",
      call: "",

      // BOOLEAN
      pacienteExiste: false,
      validar_rut: false,
      espera_envio: false,
      mensaje_pendiente: false,
    };
  }

  componentDidMount() {
    this.regiones();
  }

  // API REST

  regiones = () => {
    axios
      .get(API_URL + "web/traer_regiones/")
      .then((res) => {
        this.setState({
          ArrRegion: res.data.datos,
        });
      })
      .catch((error) => {});
  };

  cambiarValorRegion = (e, value) => {
    axios
      .post(API_URL + "web/traer_comunas/", { reg_id: e.target.value })
      .then((res) => {
        this.setState({
          region: e.target.value,
          ArrComunas: res.data.datos,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  mensajeExito = () => {
    this.setState({ espera_envio: false }, this.volver());
  };

  enviarContacto = () => {
    const { nombre, correo, telefono, rol, region, comuna, mensaje, ver_rut } = this.state;

    const data = {
      rut: ver_rut,
      nombre: nombre,
      correo: correo,
      telefono: telefono,
      rol: rol,
      region: region,
      comuna: comuna,
      mensaje: mensaje,
    };

    if (this.validaciones()) {
      this.setState({ espera_envio: true }, () => {
        axios.post(API_URL + "web/envio_contactenos/", data).then((res) => {
          if (res.data.estado) {
            var mensajeRespuesta = document.getElementById("mensajeContacto");

            if (res.data.mensaje == "Existe contacto") {
              mensajeRespuesta.innerHTML = `
              <span style="font-size: clamp(10px, 2.69vw, 66px);">
                Tiene un mensaje enviado exitosamente,
              </span> 
              <br /> pronto nos comunicaremos
              <br /> según lo indicado en el texto que recibimos. 
              <br /> Saludos.
            `;
            } else {
              mensajeRespuesta.innerHTML = `
              <span style="font-size: clamp(30px, 2.69vw, 66px);">
              Mensaje enviado con éxito
              </span> 
              <br />
              Pronto te responderemos. 
              <br />
              ¡Gracias por contactarnos!
          `;
            }

            this.setState({
              ArrComunas: [],
              nombre: "",
              correo: "",
              telefono: "",
              rut: "",
              rol: "",
              mensaje: "",
              ver_rut: "",
              mensajeExiste: "",
              comuna: 0,
              region: 0,
            });
          }
        });
      });
    }
  };

  verificarRut = (event) => {
    event.preventDefault();
    const { ver_rut } = this.state;

    let data = {
      rut: ver_rut,
    };

    if (ver_rut) {
      if (!chileanRut.validate(ver_rut)) {
        this.setState({ pacienteExiste: false, validar_rut: false, errorRut: true, mensajeExiste: "RUT inválido" });
        return;
      }

      axios.post(API_URL + "usuarios/por_rut/", data).then((res) => {
        if (res.data.estado) {
          axios.get(API_URL + "web/get_cesfam_call_center/?usu_id=" + res.data.usu_id).then((res) => {
            if (res.data.estado) {
              this.setState({ pacienteExiste: true, validar_rut: true, cesfam: res.data.nombre_cesfam, call: res.data.call_center });
            } else {
              this.setState({ pacienteExiste: false, validar_rut: true });
            }
          });
        } else {
          this.setState({ pacienteExiste: false, validar_rut: true });
        }
      });
    } else {
      this.setState({ pacienteExiste: false, validar_rut: false, errorRut: true, mensajeExiste: "Campo obligatorio" });
    }
  };

  cambiarValores = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  volver = () => {
    this.setState({
      validar_rut: false,
      errorRut: false,
      pacienteExiste: false,
      msjValidacion: [],
      mensajeExiste: "",
      ver_rut: "",
    });
  };

  validaciones = () => {
    const { nombre, correo, telefono, rol, region, comuna, mensaje } = this.state;

    let validador = true;
    let msjError = {};

    if (!nombre) {
      validador = false;
      msjError["nombreErr"] = "Nombre";
    }

    if (correo != "") {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(correo)) {
        validador = false;
        msjError["correoErr"] = "Correo";
      }
    } else {
      validador = false;
      msjError["correoErr"] = "Correo";
    }
    if (!telefono) {
      validador = false;
      msjError["telefonoErr"] = "Teléfono";
    } else if (telefono.length < 9 || telefono.length > 9) {
      validador = false;
      msjError["telefonoErr"] = "Teléfono";
    }

    if (!rol) {
      validador = false;
      msjError["rolErr"] = "Rol";
    }

    if (!region) {
      validador = false;
      msjError["regionErr"] = "Region";
    }

    if (comuna == "") {
      validador = false;
      msjError["comunaErr"] = "Comuna";
    }
    if (!mensaje) {
      validador = false;
      msjError["mensajeErr"] = "Mensaje";
    }

    this.setState({ msjValidacion: msjError });
    return validador;
  };

  render() {
    const { nombreErr, correoErr, telefonoErr, rolErr, comunaErr, regionErr, mensajeErr } = this.state.msjValidacion;
    const { validar_rut, ver_rut, pacienteExiste, ArrComunas, ArrRegion, errorRut, mensajeExiste, espera_envio, cesfam, call } = this.state;

    return (
      <>
        <div className={ContactanosCSS.imgPrincipal}>
          <img src={camioneta} alt="fondo de contacto" className={ContactanosCSS.imgBienvenida} />
          <div className={ContactanosCSS.cntTituloPrincipal}>
            <h2 className={ContactanosCSS.titulo} style={{ marginTop: "clamp(10px,5vw,90px)" }}>
              Contacto
            </h2>
            <h4 className={ContactanosCSS.subTitulo}>Donde nos necesites, ahí estaremos...</h4>
          </div>
          <img src={camionetaMobile} alt="fondo de contacto" className={ContactanosCSS.imgBienvenidaMobile} />

          {/* <div className={ContactanosCSS.cntTituloPrincipal}>
            <h2 className={ContactanosCSS.titulo} style={{ marginTop: "clamp(10px,5vw,90px)" }}>
              Contacto
            </h2>
            <h4 className={ContactanosCSS.subTitulo}>Donde nos necesites, ahí estaremos...</h4>
          </div> */}
          {/* <img src={camionetaMobile} alt="fondo de contacto" className={ContactanosCSS.imgBienvenidaMobile} /> */}

          {!validar_rut ? (
            <>
              <div className={ContactanosCSS.cntFormularioRut}>
                <form onSubmit={this.verificarRut} className={ContactanosCSS.formRut}>
                  <label className={ContactanosCSS.labelRut}>Ingrese su RUT: </label>
                  <input
                    className={ContactanosCSS.inputRut}
                    onChange={this.cambiarValores}
                    value={ver_rut}
                    name="ver_rut"
                    maxLength={12}
                    style={errorRut ? { border: "red solid 0.1vw" } : null}
                  />
                  {mensajeExiste && (
                    <p style={{ fontSize: "clamp(10px,1.4vw,25px)", margin: "clamp(1px, 0.5vw,9px) 0px 0px clamp(8px, 1vw, 8px)" }}>
                      {mensajeExiste}
                    </p>
                  )}
                  <button className={ContactanosCSS.btnVerificarRut} type="submit">
                    Buscar
                  </button>
                </form>
              </div>
            </>
          ) : (
            <>
              {pacienteExiste ? (
                <>
                  <div className={ContactanosCSS.cntPacienteExiste}>
                    <p className={ContactanosCSS.msjParrafo}>
                      <span style={{ fontWeight: "bold" }}>Vecin@</span>, según nuestros registros <br />
                      usted se encuentra inscrito en el centro de salud <span style={{ fontWeight: "bold" }}>{cesfam}</span>
                    </p>
                    <br />
                    <p className={ContactanosCSS.msjParrafo}>
                      Puede llamar al <span style={{ fontWeight: "bold" }}>{call}</span> o <br />
                      descargar la aplicación "HoraSalud" <br />
                      disponible para Android, IOS, y Huawei.
                    </p>
                    <br />

                    <p className={ContactanosCSS.msjParrafo}>Escanea el QR para descargar la app</p>
                    <img src={qr} style={{ width: "65%", maxWidth: "573px" }} />
                    <br />
                    <button className={ContactanosCSS.btnVerificarRut} style={{ width: "20%" }} onClick={this.volver}>
                      Volver
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {!espera_envio ? (
                    <div className={ContactanosCSS.contenedorFormulario}>
                      {/* ------------------------------------------------------------------ */}

                      <div className={ContactanosCSS.cntFilaContacto}>
                        <div className={ContactanosCSS.cntCasilla}>
                          <label className={ContactanosCSS.labelTxt}>Nombre Apellido</label>
                          <input
                            className={ContactanosCSS.inputTxt}
                            onChange={this.cambiarValores}
                            name="nombre"
                            style={nombreErr && { border: "solid #f05555 0.1vw" }}
                          />
                        </div>

                        <div className={ContactanosCSS.cntCasilla}>
                          <label className={ContactanosCSS.labelTxt}>Correo electrónico</label>
                          <input
                            className={ContactanosCSS.inputTxt}
                            placeholder="correo@correo.cl"
                            onChange={this.cambiarValores}
                            name="correo"
                            style={correoErr && { border: "solid #f05555 0.1vw" }}
                          />
                        </div>
                      </div>

                      {/* ------------------------------------------------------------------ */}

                      <div className={ContactanosCSS.cntFilaContacto}>
                        <div className={ContactanosCSS.cntCasilla}>
                          <label className={ContactanosCSS.labelTxt}>Teléfono</label>
                          <div className={ContactanosCSS.cntTelefono} style={telefonoErr && { border: "solid #f05555 0.1vw" }}>
                            <label>+ 56</label>
                            <input
                              type="text"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 9);
                              }}
                              className={ContactanosCSS.tel}
                              onChange={this.cambiarValores}
                              name="telefono"
                            />
                          </div>
                        </div>

                        <div className={ContactanosCSS.cntCasilla}>
                          <label className={ContactanosCSS.labelTxt}>Indique su cargo</label>
                          <input
                            className={ContactanosCSS.inputTxt}
                            onChange={this.cambiarValores}
                            name="rol"
                            style={rolErr && { border: "solid #f05555 0.1vw" }}
                          />
                        </div>
                      </div>

                      {/* ------------------------------------------------------------------ */}

                      <div className={ContactanosCSS.cntFilaContacto}>
                        <div className={ContactanosCSS.cntCasilla}>
                          <label className={ContactanosCSS.labelTxt}>Región</label>
                          <select
                            className={ContactanosCSS.select}
                            onChange={this.cambiarValorRegion}
                            style={regionErr && { border: "solid #f05555 0.1vw" }}>
                            <option id={0} selected disabled value={0}>
                              Selecciona...
                            </option>
                            {ArrRegion.map((reg) => (
                              <option key={reg.reg_id} value={reg.reg_id}>
                                {reg.reg_nombre}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className={ContactanosCSS.cntCasilla}>
                          <label className={ContactanosCSS.labelTxt}>Comuna</label>
                          <select
                            className={ContactanosCSS.select}
                            onChange={this.cambiarValores}
                            name="comuna"
                            style={comunaErr && { border: "solid #f05555 0.1vw" }}>
                            <option id={0} selected disabled value={0}>
                              Selecciona...
                            </option>
                            {ArrComunas.map((com) => (
                              <option key={com.com_id} value={com.com_id}>
                                {com.com_nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/* ------------------------------------------------------------------ */}

                      <div className={ContactanosCSS.cntFilaContacto}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <label className={ContactanosCSS.labelTxt}>Mensaje</label>
                          <textarea
                            className={ContactanosCSS.msjLargo}
                            placeholder="Escribe tus dudas y/o especificaciones que creas necesarias"
                            onChange={this.cambiarValores}
                            name="mensaje"
                            style={mensajeErr && { border: "solid #f05555 0.1vw" }}></textarea>
                        </div>
                      </div>

                      {/* ------------------------------------------------------------------ */}
                      <div style={{ display: "flex" }}>
                        <button className={ContactanosCSS.btnContacto} onClick={this.volver}>
                          Volver
                        </button>

                        <button className={ContactanosCSS.btnContacto} onClick={this.enviarContacto}>
                          Enviar
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className={ContactanosCSS.cntMesajeExito}>
                      <p id="mensajeContacto"></p>

                      <button className={ContactanosCSS.btnContacto} style={{ width: "30%" }} onClick={this.mensajeExito}>
                        Aceptar
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}
