import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 } from "uuid";
import { config } from "react-spring";

//IMG
import salucin_abuelito from "./../../assets/img/opiniones/salusin carolina.svg";
import salucin_joven from "./../../assets/img/opiniones/salusin usuario.svg";
import salucin_mujer from "./../../assets/img/opiniones/salusin claudia.svg";
import salucin_rosado from "./../../assets/img/opiniones/salusin karoline.svg";
import salucin_omar from "./../../assets/img/opiniones/salusin omar.svg";
import salucin_andres from "./../../assets/img/opiniones/salusin andres.svg";

import cerebro from "./../../assets/img/opiniones/cerebro_naranjo.svg";
import cerebroAzul from "./../../assets/img/opiniones/cerebro_azul.svg";
import cerebroMorado from "./../../assets/img/opiniones/cerebro_morado.svg";
import cerebroVerde from "./../../assets/img/opiniones/cerebro_verde.svg";
import cerebroVerdeAgua from "./../../assets/img/opiniones/cerebro_verde_agua.svg";
import cerebroBurdeo from "./../../assets/img/opiniones/cerebro_burdeo.svg";

import deseno_lateral from "./../../assets/img/servicios/lineas izquierda.svg";

//CSS
import OpinionesCSS from "./../../assets/css/Web/opiniones.module.css";

const getTouches = (evt) => {
  return (
    evt.touches || evt.originalEvent.touches // browser API
  );
};

const NombreOpinion = ["Carolina", "María", "Sergio", "Luisa", "Omar", "Andrés"];

const opiniones = [
  "Gracias a la App, ya no olvido tomarme mis remedios y me avisan cuando me toca control.",
  "Si. Me parece muy bien. Claro porque desde la casa podemos pedir las horas no tenemos que venir, a veces no tenemos con quién dejar a los niños",
  "A mi la verdad no me gustaba al principio, pero la use una vez y de ahí no la dejo de usar, llevo la cuenta de mis medicamentos y mis atenciones.",
  "La App me gusta mucho, porque antes tenia que venir al pueblo a pedir la hora del médico pero ahora uso la maravilla esta y me ahorro la levanta y el viaje temprano.",
  "Mi viejita ya no puede ir ha hacer la cola en la mañana, el alcalde con este sistema nos mejoró harto la vida.",
  "Antes de HoraSalud, se hacían tremendas colas en la madrugada, habían peleas todos los días, pero desde que instalaron el Tótem, las cosas se ordenaron y podemos atender a los Adultos mayores de mejor manera.",
];
export default class Opiniones extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 1,
    showNavigation: false,
    enableSwipe: true,
    config: config.slow,
  };

  slides = [
    {
      key: v4(),
      content: (
        <div className={OpinionesCSS.cuadroTexto} style={{ background: "#7bbae2" }}>
          <img alt="imagen" src={cerebroAzul} className={OpinionesCSS.cerebro} />
          <div className={OpinionesCSS.cntTextoOpiniones}>
            <div className={OpinionesCSS.cntUsuario}>
              <img alt="imagen usuario" src={salucin_abuelito} className={OpinionesCSS.imgUsuario} />
              <div className={OpinionesCSS.cnt_cuadro} style={{ background: "#49a2ce" }}>
                <span className={OpinionesCSS.nombreOpinion}>{NombreOpinion[0]}</span> <br></br>
                <span className={OpinionesCSS.comunaUsuario}>Cristo vive.</span>
              </div>
            </div>
            <div className={OpinionesCSS.cntTexto}>
              <p className={OpinionesCSS.texto}>"{opiniones[0]}"</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: v4(),
      content: (
        <div className={OpinionesCSS.cuadroTexto} style={{ background: "#94c57e" }}>
          <img alt="imagen" src={cerebroVerde} className={OpinionesCSS.cerebro} />
          <div className={OpinionesCSS.cntTextoOpiniones}>
            <div className={OpinionesCSS.cntUsuario}>
              <img alt="imagen usuario" src={salucin_joven} className={OpinionesCSS.imgUsuario} />
              <div className={OpinionesCSS.cnt_cuadro} style={{ background: "#83b769" }}>
                <span className={OpinionesCSS.nombreOpinion}>{NombreOpinion[1]}</span> <br></br>
                <span className={OpinionesCSS.comunaUsuario}>Antofagasta.</span>
              </div>
            </div>
            <div className={OpinionesCSS.cntTexto}>
              <p className={OpinionesCSS.texto}>"{opiniones[1]}"</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: v4(),
      content: (
        <div className={OpinionesCSS.cuadroTexto} style={{ background: "#e27695" }}>
          <img alt="imagen" src={cerebro} className={OpinionesCSS.cerebro} />

          <div className={OpinionesCSS.cntTextoOpiniones}>
            <div className={OpinionesCSS.cntUsuario}>
              <img alt="imagen usuario" src={salucin_mujer} className={OpinionesCSS.imgUsuario} />

              <div className={OpinionesCSS.cnt_cuadro} style={{ background: "#d65480" }}>
                <span className={OpinionesCSS.nombreOpinion}>{NombreOpinion[2]}</span> <br></br>
                <span className={OpinionesCSS.comunaUsuario}>Purranque.</span>
              </div>
            </div>
            <div className={OpinionesCSS.cntTexto}>
              <p className={OpinionesCSS.texto}>"{opiniones[2]}"</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: v4(),
      content: (
        <div className={OpinionesCSS.cuadroTexto} style={{ background: "#b195cd" }}>
          <img alt="imagen" src={cerebroMorado} className={OpinionesCSS.cerebro} />

          <div className={OpinionesCSS.cntTextoOpiniones}>
            <div className={OpinionesCSS.cntUsuario}>
              <img alt="imagen usuario" src={salucin_rosado} className={OpinionesCSS.imgUsuario} />
              <div className={OpinionesCSS.cnt_cuadro} style={{ background: "#9273b8" }}>
                <span className={OpinionesCSS.nombreOpinion}>{NombreOpinion[3]}</span> <br></br>
                <span className={OpinionesCSS.comunaUsuario}>Nueva imperial.</span>
              </div>
            </div>
            <div className={OpinionesCSS.cntTexto}>
              <p className={OpinionesCSS.texto}>"{opiniones[3]}"</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: v4(),
      content: (
        <div className={OpinionesCSS.cuadroTexto} style={{ background: "#b54163" }}>
          <img alt="imagen" src={cerebroBurdeo} className={OpinionesCSS.cerebro} />

          <div className={OpinionesCSS.cntTextoOpiniones}>
            <div className={OpinionesCSS.cntUsuario}>
              <img alt="imagen usuario" src={salucin_omar} className={OpinionesCSS.imgUsuario} />
              <div className={OpinionesCSS.cnt_cuadro} style={{ background: "#a0355d" }}>
                <span className={OpinionesCSS.nombreOpinion}>{NombreOpinion[4]}</span> <br></br>
                <span className={OpinionesCSS.comunaUsuario}>Peñaflor.</span>
              </div>
            </div>
            <div className={OpinionesCSS.cntTexto}>
              <p className={OpinionesCSS.texto}>"{opiniones[4]}"</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: v4(),
      content: (
        <div className={OpinionesCSS.cuadroTexto} style={{ background: "#6cbfb5" }}>
          <img alt="imagen" src={cerebroVerdeAgua} className={OpinionesCSS.cerebro} />

          <div className={OpinionesCSS.cntTextoOpiniones}>
            <div className={OpinionesCSS.cntUsuario}>
              <img alt="imagen usuario" src={salucin_andres} className={OpinionesCSS.imgUsuario} />
              <div className={OpinionesCSS.cnt_cuadro} style={{ background: "#4da090" }}>
                <span className={OpinionesCSS.nombreOpinion}>{NombreOpinion[5]}</span> <br></br>
                <span className={OpinionesCSS.comunaUsuario}>Funcionario de Macul.</span>
              </div>
            </div>
            <div className={OpinionesCSS.cntTexto}>
              <p className={OpinionesCSS.texto}>"{opiniones[5]}"</p>
            </div>
          </div>
        </div>
      ),
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = (name, value) => {
    this.setState({
      [name]: parseInt(value, 10) || 0,
    });
  };

  handleTouchStart = (evt) => {
    if (!this.state.enableSwipe) {
      return;
    }

    const firstTouch = getTouches(evt)[0];
    this.setState({
      ...this.state,
      xDown: firstTouch.clientX,
      yDown: firstTouch.clientY,
    });
  };

  handleTouchMove = (evt) => {
    if (!this.state.enableSwipe || (!this.state.xDown && !this.state.yDown)) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = this.state.xDown - xUp;
    let yDiff = this.state.yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        /* left swipe */
        this.setState({
          goToSlide: this.state.goToSlide + 1,
          xDown: null,
          yDown: null,
        });
      } else {
        /* right swipe */
        this.setState({
          goToSlide: this.state.goToSlide - 1,
          xDown: null,
          yDown: null,
        });
      }
    }
  };

  render() {
    const { goToSlide, offsetRadius, showNavigation, config } = this.state;

    return (
      <>
        <h1 className={OpinionesCSS.tituloPrincipal}>Testimonios</h1>
        <div className={OpinionesCSS.contenedorOpiniones} style={{ position: "relative" }}>
          <img alt="linea cibernetica" src={deseno_lateral} className={OpinionesCSS.rayasLaterales} />

          <div className="contenedorOpiniones" onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove}>
            <Carousel
              showThumbs={false}
              slides={this.slides}
              goToSlide={goToSlide}
              offsetRadius={offsetRadius}
              showNavigation={showNavigation}
              animationConfig={config}
            />
          </div>
        </div>
      </>
    );
  }
}
