import React, { Component } from "react";

//CSS
import "../../../assets/css/general.css";
import InicioCSS from "../../../assets/css/Mobile/Inicio.module.css";

export default class Slide extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    // Detecta cambios en la prop 'slide'
    if (prevProps.slide !== this.props.slide) {
      if (this.props.slide) {
        setTimeout(() => {
          document.getElementById("contenedorSlide").style.display = "flex";
          document.getElementById("contenedorSlide").style.zIndex = "99";
        }, 10);
        for (let i = 1; i < 6; i++) {
          setTimeout(() => {
            const button = document.getElementById("btn_" + i);
            if (button) {
              button.style.right = "0%";
            }
          }, i * 100);
        }
      } else {
        for (let i = 1; i < 6; i++) {
          setTimeout(() => {
            const button = document.getElementById("btn_" + i);
            if (button) {
              button.style.right = "-100%";
            }
          }, i * 100);
        }
        setTimeout(() => {
          document.getElementById("contenedorSlide").style.zIndex = "-99";
        }, 1000);
      }
    }
  }

  cerrarMenuNavegacion = (nav) => {
    this.props.ocultarSlide();
    this.props.cambiarEstado();
    setTimeout(() => {
      this.props.scroll(nav);
    }, 200);
  };

  render() {
    return (
      <>
        <div id="contenedorSlide" className={this.props.slide ? InicioCSS.sidebar_activo : InicioCSS.sidebar_desactivo}>
          <a id="btn_1" className={InicioCSS.btnOpciones} onClick={() => this.cerrarMenuNavegacion(4)}>
            <button className={InicioCSS.btn}>
              <span className={InicioCSS.btnOpacidad}></span>
              <span className={InicioCSS.btnTexto}>Mision</span>
            </button>
          </a>
          <a id="btn_2" className={InicioCSS.btnOpciones} onClick={() => this.cerrarMenuNavegacion(2)}>
            <button className={InicioCSS.btn}>
              <span className={InicioCSS.btnOpacidad}></span>
              <span className={InicioCSS.btnTexto}>Servicios</span>
            </button>
          </a>
          <a id="btn_3" className={InicioCSS.btnOpciones} onClick={() => this.cerrarMenuNavegacion(5)}>
            <button className={InicioCSS.btn}>
              <span className={InicioCSS.btnOpacidad}></span>
              <span className={InicioCSS.btnTexto}>Contacto</span>
            </button>
          </a>
          <a id="btn_4" className={InicioCSS.btnOpciones} onClick={() => this.props.estadoAcceso(true)}>
            <button className={InicioCSS.btn}>
              <span className={InicioCSS.btnOpacidad}></span>
              <span className={InicioCSS.btnTexto}>Accesos</span>
            </button>
          </a>
        </div>
      </>
    );
  }
}
