import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

export default class Terminos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      titulo: this.props.titulo,
      tipo: this.props.tipo,
    };
  }

  CerrarModal = () => {
    this.props.cerrar();
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  render() {
    const { titulo, tipo } = this.state;

    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={this.Transition}
        keepMounted
        onClose={this.CerrarModal}
        aria-describedby="alert-dialog-slide-description"
        id="Dialog_Terminos_y_politicas">
        <DialogTitle style={{ textAlign: "center" }}>{titulo}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <iframe
              style={{ height: "100%", width: "100%", border: "none" }}
              src={tipo == 1 ? "https://horasalud.cl/web/img/Privacidad.pdf" : "https://horasalud.cl/web/img/terminos.pdf"}></iframe>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }
}
