import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";

import HomeWeb from "./views/Web/Inicio";
import HomeMobile from "./views/Mobile/InicioMobile";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const mobileKeywords = ["iPhone", "Android", "Mobile", "Tablet"];

    const isMobile = mobileKeywords.some((keyword) => userAgent.includes(keyword));

    if (isMobile) {
      setIsMobile(true);
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <BrowserRouter basename="/">
      <Routes>
        {isMobile ? (
          <>
            <Route path="/" element={<HomeMobile />} />
          </>
        ) : (
          <>
            <Route path="/" element={<HomeWeb />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
