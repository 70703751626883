import React, { Component } from "react";
//CSS
import AnniosCSS from "../../assets/css/Web/annios.module.css";
import annios from "../../assets/img/mobile/12annios.webp";

export default class Annios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            background: "rgb(3, 152, 217)",
            paddingBottom: "37vw",
            margin: "auto",
          }}>
          <img src={annios} style={{ width: "100%" }} />
          <p style={{ color: "white", position: "absolute", bottom: "4vw", width: "86%", fontSize: "5.5vw", textAlign: "center" }}>
            “Más de 12 años de experiencia transformando la salud pública. Confianza, innovación y soluciones que hacen la diferencia.”
          </p>
        </div>
      </>
    );
  }
}
