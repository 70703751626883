import React, { Component } from "react";

//CSS
import Imagen3TextoCSS from "../../assets/css/Mobile/Imagen3TextoCSS.module.css";

//IMG
import fdo_accesos from "../../assets/img/mobile/fondo_accesos.webp";
import btn_funcionario from "../../assets/img/mobile/btnFuncionarios.svg";
import btn_salud from "../../assets/img/mobile/btnSalud.svg";
import btn_teleconsulta from "../../assets/img/mobile/btnTeleconsulta.svg";
import btn_usuario from "../../assets/img/mobile/btnUsuario.svg";
import btn_social from "../../assets/img/mobile/social.svg";

export default class Imagen3Texto extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className={Imagen3TextoCSS.divPrincipal}>
          <span
            onClick={() => this.props.cambiarEstado(false)}
            style={{ position: "absolute", top: "2%", right: "8%", fontSize: "12vw", color: "white", zIndex: "99", fontFamily: "sans-serif" }}>
            X
          </span>
          <img src={fdo_accesos} className={Imagen3TextoCSS.fdo_accesos} />
          <a href="https://horasalud.cl/web/portal/" target="_blank">
            <img src={btn_usuario} className={Imagen3TextoCSS.btn_usuario} />
          </a>
          <a href="https://panelv2hs.cl/salud/" target="_blank">
            <img src={btn_salud} className={Imagen3TextoCSS.btn_salud} />
          </a>

          <a href="https://panelv2hs.cl/" target="_blank">
            <img src={btn_funcionario} className={Imagen3TextoCSS.btn_funcionario} />
          </a>

          <a href="https://panelv2hs.cl/web_medico/" target="_blank">
            <img src={btn_teleconsulta} className={Imagen3TextoCSS.btn_teleconsulta} />
          </a>
          <a href="https://panelv2hs.cl/departamento/" target="_blank">
            <img src={btn_social} className={Imagen3TextoCSS.btn_social} />
          </a>
        </div>
      </>
    );
  }
}
