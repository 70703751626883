import React, { Component } from "react";
//CSS
import AnniosCSS from "../../assets/css/Web/annios.module.css";
import annios from "../../assets/img/12annios.webp";

export default class Annios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={AnniosCSS.cntPrincipalAnnios}>
          <div className={AnniosCSS.cntParrafoAnnios}>
            <p>
              “Más de 12 años de experiencia transformando la salud pública.
              <br /> Confianza, innovación y soluciones que hacen la diferencia.”
            </p>
          </div>
          <img src={annios} style={{ height: "100%", maxWidth: "630px", maxHeight: "302px", position: "absolute", right: "0" }} />
        </div>
      </>
    );
  }
}
