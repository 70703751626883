import React, { Component } from "react";
import celuLogin from "./../../assets/img/carrucel/login-cel.png";
import telemedicina from "./../../assets/img/carrucel/telemedicina.png";
import totem1 from "./../../assets/img/carrucel/totem/totem.png";
import portal from "./../../assets/img/carrucel/portal-web.png";
import gestion from "./../../assets/img/carrucel/charlas-capacitacion.png";
import ia from "./../../assets/img/carrucel/ia.png";
import bigdata from "./../../assets/img/carrucel/big-data.png";
import call from "./../../assets/img/carrucel/call_center/call2.png";

import linea_app from "./../../assets/img/carrucel/linea blanca.svg";

import icon_app from "./../../assets/img/carrucel/app.svg";
import icon_telemedicina from "./../../assets/img/carrucel/lista de espera.svg";
import icon_totem from "./../../assets/img/carrucel/totem.svg";
import icon_portal from "./../../assets/img/carrucel/web.svg";
import icon_gestion from "./../../assets/img/carrucel/capacitacion.svg";
import icon_ia from "./../../assets/img/carrucel/ia.svg";
import icon_bigdata from "./../../assets/img/carrucel/big data.svg";
import icon_call from "./../../assets/img/carrucel/call.svg";

import fdo_app from "../../assets/img/carrucel/app.webp";
import fdo_web from "../../assets/img/carrucel/web.webp";
import fdo_telemedicina from "../../assets/img/carrucel/telemedicina.webp";
import fdo_call from "../../assets/img/carrucel/call.webp";
import fdo_totem from "../../assets/img/carrucel/totem.webp";
import fdo_gestion from "../../assets/img/carrucel/gestion.webp";
import fdo_bigdata from "../../assets/img/carrucel/informacion.webp";
import fdo_ia from "../../assets/img/carrucel/ia.webp";

import next from "../../assets/img/carrucel/next.svg";

//CSS
import CarrucelCSS from "./../../assets/css/Web/carrucel.module.css";
import ImgTextoCSS from "../../assets/css/Web/ImgTexto.module.css";

export default class Carrucel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indiceActual: 0,
    };

    this.ultimaActualizacion = performance.now();
    this.frameId = null;
  }
  componentDidMount() {
    this.precargaImagen();
    this.animar();
  }
  componentWillUnmount() {
    // Cancelar la animación al desmontar el componente
    cancelAnimationFrame(this.frameId);
  }

  animar = () => {
    const tiempoPorSlide = 10000;
    const ahora = performance.now();

    if (ahora - this.ultimaActualizacion >= tiempoPorSlide) {
      this.cambiarSlide();
      this.ultimaActualizacion = ahora;
    }

    this.frameId = requestAnimationFrame(this.animar);
  };

  cambiarSlide = () => {
    const slides = [fdo_app, fdo_web, fdo_telemedicina, fdo_call, fdo_totem, fdo_gestion, fdo_bigdata, fdo_ia];
    this.setState((prevState) => ({
      indiceActual: (prevState.indiceActual + 1) % slides.length,
    }));
  };

  cambiarSlideManual = (accion) => {
    const slides = [fdo_app, fdo_web, fdo_telemedicina, fdo_call, fdo_totem, fdo_gestion, fdo_bigdata, fdo_ia];
    const totalSlides = slides.length;

    this.setState((prevState) => {
      let nuevoIndice;

      if (accion === "next") {
        nuevoIndice = (prevState.indiceActual + 1) % totalSlides;
      } else if (accion === "back") {
        nuevoIndice = (prevState.indiceActual - 1 + totalSlides) % totalSlides;
      }

      // Reiniciar el tiempo para que animar no se active antes de los 10 segundos
      this.ultimaActualizacion = performance.now();
      return { indiceActual: nuevoIndice };
    });
    this.frameId = requestAnimationFrame(this.animar);
  };

  precargaImagen = () => {
    const imagenes = [fdo_app, fdo_web, fdo_telemedicina, fdo_call, fdo_totem, fdo_gestion, fdo_bigdata, fdo_ia];
    return imagenes.map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });
  };

  componentWillUnmount() {
    clearTimeout(this.slideTimer);
  }

  render() {
    const { indiceActual } = this.state;
    const tiempoTransicion = 2000;
    const slides = [fdo_app, fdo_web, fdo_telemedicina, fdo_call, fdo_totem, fdo_gestion, fdo_bigdata, fdo_ia];

    const cnt_slide = [
      <div className={CarrucelCSS.cntApp}>
        <img src={fdo_app} className={CarrucelCSS.imgFondo} />
        <img src={celuLogin} className={CarrucelCSS.app} />
        <div className={CarrucelCSS.cntTextoApp}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_app} className={CarrucelCSS.iconApp} />
            <h4 className={CarrucelCSS.tituloSegundario}>Aplicación Móvil</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Gestiona tus horas médicas de forma intuitiva,</p>
              <p className={CarrucelCSS.parrafo}>
                con una app creada junto a los usuarios y funcionarios <br />
              </p>
              <p className={CarrucelCSS.parrafo}>
                del sistema público para garantizar una experiencia <br />
              </p>
              <p className={CarrucelCSS.parrafo}>amigable y accesible desde cualquier lugar.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntWeb}>
        <img src={fdo_web} className={CarrucelCSS.imgFondo} />
        <img src={portal} className={CarrucelCSS.web} />
        <div className={CarrucelCSS.cntTextoWeb}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_portal} className={CarrucelCSS.iconWeb} />
            <h4 className={CarrucelCSS.tituloSegundario}>Portal Web</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Centraliza la gestión de horas médicas en un portal</p>
              <p className={CarrucelCSS.parrafo}>eficiente y vincúlalo fácilmente a tu sitio web.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntTelemedicina}>
        <img src={fdo_telemedicina} className={CarrucelCSS.imgFondo} />
        <img src={telemedicina} className={CarrucelCSS.telemedicina} />
        <div className={CarrucelCSS.cntTextoTelemedicina}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_telemedicina} className={CarrucelCSS.iconTelemedicina} />
            <h4 className={CarrucelCSS.tituloSegundario}>Telemedicina</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Conecta pacientes y profesionales en atención</p>
              <p className={CarrucelCSS.parrafo}>médica a distancia, accesible desde cualquier</p>
              <p className={CarrucelCSS.parrafo}>dispositivo con nuestra Aplicación Móvil y</p>
              <p className={CarrucelCSS.parrafo}>el Portal Web.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntCall}>
        <img src={fdo_call} className={CarrucelCSS.imgFondo} />
        <img src={call} className={CarrucelCSS.call} />

        <div className={CarrucelCSS.cntTextoCall}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_call} className={CarrucelCSS.iconCall} />
            <h4 className={CarrucelCSS.tituloSegundario}>Call Center</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Brinda un servicio cercano y efectivo con un</p>
              <p className={CarrucelCSS.parrafo}>sistema de atención telefónica que se adapta</p>
              <p className={CarrucelCSS.parrafo}>a las necesidades de tus usuarios, asegurando</p>
              <p className={CarrucelCSS.parrafo}>rapidez y claridad en cada interacción.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntTotem}>
        <img src={fdo_totem} className={CarrucelCSS.imgFondo} />
        <img src={totem1} className={CarrucelCSS.totem} />
        <div className={CarrucelCSS.cntTextoTotem}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_totem} className={CarrucelCSS.iconTotem} />
            <h4 className={CarrucelCSS.tituloSegundario}>Tótem</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Organiza el flujo de pacientes, cumple con la Ley</p>
              <p className={CarrucelCSS.parrafo}>de Atención Preferente y personaliza la experiencia</p>
              <p className={CarrucelCSS.parrafo}>de tus usuarios, aprovechando tiempos de espera,</p>
              <p className={CarrucelCSS.parrafo}>entregándoles información dirigida y manteniendo</p>
              <p className={CarrucelCSS.parrafo}>la trazabilidad en cada paso.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntGestion}>
        <img src={fdo_gestion} className={CarrucelCSS.imgFondo} />
        <img src={gestion} className={CarrucelCSS.gestion} />
        <div className={CarrucelCSS.cntTextoGestion}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_gestion} className={CarrucelCSS.iconGestion} />
            <h4 className={CarrucelCSS.tituloSegundario}>Gestión del Cambio</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Impulsa la adopción de nuevas tecnologías con</p>
              <p className={CarrucelCSS.parrafo}>capacitaciones para tu equipo y alfabetización</p>
              <p className={CarrucelCSS.parrafo}>digital para los pacientes, asegurando una</p>
              <p className={CarrucelCSS.parrafo}>transición efectiva.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntBigData}>
        <img src={fdo_bigdata} className={CarrucelCSS.imgFondo} />
        <img src={bigdata} className={CarrucelCSS.bigdata} />
        <div className={CarrucelCSS.cntTextoBigData}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_bigdata} className={CarrucelCSS.iconBigData} />
            <h4 className={CarrucelCSS.tituloSegundario}>
              Información en línea y <br /> en simple
            </h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Generamos datos claros y visuales en tiempo real,</p>
              <p className={CarrucelCSS.parrafo}>brindando a las direcciones de salud las herramientas</p>
              <p className={CarrucelCSS.parrafo}>necesarias para tomar decisiones informadas y mejorar</p>
              <p className={CarrucelCSS.parrafo}>la gestión de recursos, junto con modelos predictivos.</p>
            </span>
          </div>
        </div>
      </div>,

      <div className={CarrucelCSS.cntIa}>
        <img src={fdo_ia} className={CarrucelCSS.imgFondo} />
        <img src={ia} className={CarrucelCSS.ia} />
        <div className={CarrucelCSS.cntTextoIa}>
          <div className={CarrucelCSS.cuadroInformacion}>
            <img src={linea_app} className={CarrucelCSS.linea} />
            <img src={icon_ia} className={CarrucelCSS.iconIa} />
            <h4 className={CarrucelCSS.tituloSegundario}>VICOS IA</h4>
            <span className={CarrucelCSS.textoCarrucel}>
              <p className={CarrucelCSS.parrafo}>Primera Inteligencia Artificial desarrollada</p>
              <p className={CarrucelCSS.parrafo}>íntegramente en Chile para la salud pública,</p>
              <p className={CarrucelCSS.parrafo} style={{ marginBottom: "clamp(0px, 1vw, 18px)" }}>
                diseñada para aprender, mejorar y ayudar.
              </p>
              <p className={CarrucelCSS.parrafo}>
                Con <span style={{ fontWeight: "bold" }}>VICOS</span> podrás segmentar y gestionar la
              </p>
              <p className={CarrucelCSS.parrafo}>demanda, generando priorizaciones de los pacientes,</p>
              <p className={CarrucelCSS.parrafo}>además de optimizar la gestión de las listas de espera.</p>
            </span>
          </div>
        </div>
      </div>,
    ];

    return (
      <>
        <h2 className={CarrucelCSS.tituloCarrucel}>Productos y servicios</h2>
        <div className={CarrucelCSS.imgPrincipal}>
          {slides.map((slide, index) => {
            const esActual = indiceActual === index;
            const esSiguiente = indiceActual === (index - 1 + slides.length) % slides.length;
            const esAnterior = indiceActual === (index + 1) % slides.length;

            return (
              <>
                <div
                  key={index}
                  /* className={`${CarrucelCSS.carrucel}`} */
                  className="slide"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    opacity: esActual ? 1 : 0,
                    transform: esActual
                      ? "translateX(0)"
                      : esSiguiente
                      ? "translateX(100%)" // Mueve el siguiente slide fuera de la vista (a la derecha)
                      : esAnterior
                      ? "translateX(-100%)" // Mueve el anterior slide fuera de la vista (a la izquierda)
                      : "translateX(0)",
                    transition: `transform ${tiempoTransicion}ms ease, opacity ${tiempoTransicion}ms ease`,
                    zIndex: esActual ? 8 : 0,
                  }}>
                  {cnt_slide[index]}
                  <img src={next} onClick={() => this.cambiarSlideManual("back")} className={CarrucelCSS.siguiente} />
                  <img src={next} onClick={() => this.cambiarSlideManual("next")} className={CarrucelCSS.anterior} />
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }
}
