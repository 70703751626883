import React, { Component } from "react";

import Terminos from "../../components/Terminos";

import FooterCSS from "../../assets/css/Mobile/Footer.module.css";

//IMG
import empresaB_premio from "../../assets/img/footer/empresa_b.svg";
import fiis from "../../assets/img/footer/fiis.svg";
import impacta from "../../assets/img/footer/impacta.svg";
import compromiso10X from "../../assets/img/footer/desafio-10x.png";
import horaSalud from "../../assets/img/footer/logo hs footer.svg";
import empresa_B from "../../assets/img/footer/empresab blanco.svg";
import chile from "../../assets/img/logo-chile-blanco.png";

import youtube from "../../assets/img/footer/youtube.svg";
import twiter from "../../assets/img/footer/twitter.svg";
import instagram from "../../assets/img/footer/instagram.svg";
import facebook from "../../assets/img/footer/facebook.svg";

export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titulo: "",
      abrirP: false,
    };
  }

  abrirPoliticas = (tipo) => {
    if (tipo == 1) {
      var titulo = "Politicas de privacidad";
    } else if (tipo == 2) {
      var titulo = "Términos y Condiciones de Uso";
    }
    this.setState({ abrirP: true, titulo: titulo, tipo: tipo });
  };

  cerrarPoliticas = () => {
    this.setState({ abrirP: false });
  };

  render() {
    return (
      <>
        <footer className={FooterCSS.footerWeb}>
          <div style={{ display: "flex", justifyContent: "center", margin: "5vw 0px", alignItems: "center" }}>
            <img alt="logo HoraSalud" src={horaSalud} className={FooterCSS.imgHs} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "5vw" }}>
            <img alt="logo Empresa B" src={empresa_B} className={FooterCSS.empresaB} />
            <img alt="logo chile" src={chile} className={FooterCSS.imgChile} />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <h4 className={FooterCSS.contenidoTitulo}>Redes sociales</h4>
              <a href="https://www.facebook.com/HoraSaludB" target="_blank">
                <img alt="facebook" src={facebook} className={FooterCSS.redesSociales} />
              </a>
              <a href="https://x.com/hora_salud" target="_blank">
                <img alt="twiter" src={twiter} className={FooterCSS.redesSociales} />
              </a>
              <a href="https://www.instagram.com/horasalud/" target="_blank">
                <img alt="instagram" src={instagram} className={FooterCSS.redesSociales} />
              </a>
              <a href="https://www.youtube.com/@horasalud/" target="_blank">
                <img alt="youtube" src={youtube} className={FooterCSS.redesSociales} />
              </a>

              <h4 className={FooterCSS.contenidoTitulo}>Legal</h4>
              <div style={{ display: "flex", flexDirection: "column", color: "white", fontSize: "4vw" }}>
                <span style={{ marginBottom: "3vw" }} onClick={() => this.abrirPoliticas(1)}>
                  Políticas de privacidad
                </span>
                <span style={{ marginBottom: "0.5vw" }} onClick={() => this.abrirPoliticas(2)}>
                  Términos y condiciones
                </span>
              </div>
            </div>
          </div>

          <div className={FooterCSS.cntFooterDerechos}>
            <span style={{ marginBottom: "3vw" }}>2012 © HoraSalud. Todos los derechos reservados.</span>
            <div className={FooterCSS.contenedorPremios}>
              <img alt="premio fiis" src={fiis} className={FooterCSS.imgFooterPremios} />
              <img alt="premio impacta" src={impacta} className={FooterCSS.imgFooterPremios} />

              <img alt="premio Best For The World" src={empresaB_premio} className={FooterCSS.imgFooterPremios} />
              <img alt="premio Compromiso" src={compromiso10X} className={FooterCSS.imgFooterPremios} />
            </div>
            <span style={{ width: "78%", fontSize: "3vw", marginBottom: "2vw" }}>
              “En HoraSalud nos comprometemos a reinvertir al menos el 50% de las ganancias en nuestra misión social”
            </span>
          </div>
        </footer>

        {this.state.abrirP && <Terminos titulo={this.state.titulo} tipo={this.state.tipo} cerrar={this.cerrarPoliticas} open={this.state.abrirP} />}
      </>
    );
  }
}
