//REACT
import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//IMG
import fdo_app from "./../../assets/img/mobile/app.webp";
import fdo_telemedicina from "./../../assets/img/mobile/telemedicina.webp";
import fdo_totem from "./../../assets/img/mobile/totem.webp";
import fdo_web from "./../../assets/img/mobile/web.webp";
import fdo_ia from "./../../assets/img/mobile/vicos.webp";
import fdo_bigdata from "./../../assets/img/mobile/en-linea.webp";
import fdo_gestion from "./../../assets/img/mobile/gestion-del-cambio.webp";
import fdo_call from "./../../assets/img/mobile/call.webp";

import icon_app from "./../../assets/img/carrucel/login-cel.png";
import icon_telemedicina from "./../../assets/img/carrucel/telemedicina.png";
import icon_totem from "./../../assets/img/carrucel/totem/totem.png";
import icon_web from "./../../assets/img/mobile/icon_portal-web.webp";
import icon_gestion from "./../../assets/img/mobile/icon_gestion-del-cambio.webp";
import icon_ia from "./../../assets/img/mobile/icon_vicos.webp";
import icon_bigdata from "./../../assets/img/carrucel/big-data.png";
import icon_call from "./../../assets/img/carrucel/call_center/call2.png";

//CSS
import CarrucelCSS from "../../assets/css/Mobile/carrucel.module.css";

export default class Carrucel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cambiarEstado = (direccion) => {
    this.props.cambiarEstado();
    setTimeout(() => {
      this.props.scroll(direccion);
    }, 200);
  };

  render() {
    return (
      <>
        <div>
          <h1 className={CarrucelCSS.tituloPrincipal}>Productos y servicios</h1>
          <div style={{ height: "100vw", overflowY: "scroll", scrollSnapType: "y mandatory" }}>
            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_app} />
              <img className={CarrucelCSS.icon_app} src={icon_app} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_app}></div>
                <h4>App Móvil</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Gestiona tus horas médicas de forma intuitiva, con una app creada junto a los usuarios y funcionarios del sistema público para
                  garantizar una experiencia amigable y accesible desde cualquier lugar.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_web} />
              <img className={CarrucelCSS.icon_web} src={icon_web} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_web}></div>
                <h4>Portal Web</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Centraliza la gestión de horas médicas en un portal eficiente y vincúlalo fácilmente a tu sitio web.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_telemedicina} />
              <img className={CarrucelCSS.icon_telemedicina} src={icon_telemedicina} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_telemedicina}></div>

                <h4>Telemedicina</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Conecta pacientes y profesionales en atención médica a distancia, accesible desde cualquier dispositivo con nuestra Aplicación Móvil
                  y el Portal Web.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_call} />
              <img className={CarrucelCSS.icon_call} src={icon_call} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad}></div>
                <div className={CarrucelCSS.opacidad_telemedicina}></div>

                <h4>Call Center</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Brinda un servicio cercano y efectivo con un sistema de atención telefónica que se adapta a las necesidades de tus usuarios,
                  asegurando rapidez y claridad en cada interacción.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_totem} />
              <img className={CarrucelCSS.icon_totem} src={icon_totem} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_telemedicina}></div>

                <h4>Tótem</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Organiza el flujo de pacientes, cumple con la Ley de Atención Preferente y personaliza la experiencia de tus usuarios, aprovechando
                  tiempos de espera, entregándoles información dirigida y manteniendo la trazabilidad en cada paso.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_gestion} />
              <img className={CarrucelCSS.icon_gestion} src={icon_gestion} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_telemedicina}></div>

                <h4>Gestión del cambio</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Impulsa la adopción de nuevas tecnologías con capacitaciones para tu equipo y alfabetización digital para los pacientes, asegurando
                  una transición efectiva.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_bigdata} />
              <img className={CarrucelCSS.icon_bigdata} src={icon_bigdata} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_bigdata}></div>
                <h4>
                  Información
                  <br />
                  en línea y
                  <br />
                  en simple
                </h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Generamos datos claros y visuales en tiempo real, brindando a las direcciones de salud las herramientas necesarias para tomar
                  decisiones informadas y mejorar la gestión de recursos, junto con modelos predictivos.
                </p>
              </div>
            </div>

            <div className={CarrucelCSS.cnt_producto}>
              <img className={CarrucelCSS.fondo} src={fdo_ia} />
              <img className={CarrucelCSS.icon_ia} src={icon_ia} />
              <div className={CarrucelCSS.cnt_texto_app}>
                <div className={CarrucelCSS.opacidad_bigdata}></div>

                <h4>VICOS IA</h4>
                <p className={CarrucelCSS.parrafo_app}>
                  Primera Inteligencia Artificial desarrollada íntegramente en Chile para la salud pública, diseñada para aprender, mejorar y ayudar.
                  <br />
                  Con <span style={{ fontWeight: "bold" }}>VICOS</span> podrás segmentar y gestionar la demanda, generando priorizaciones de los
                  pacientes, además de optimizar la gestión de las listas de espera.
                </p>
              </div>
            </div>
          </div>

          <button
            style={{
              borderRadius: "1vw",
              border: "none",
              background: "#4fafac",
              color: "white",
              fontSize: "3.5vw",
              padding: "2vw",
              width: "50%",
              margin: "0% 25% 10% 25%",
            }}
            onClick={() => this.cambiarEstado(5)}>
            ¡Contáctanos y <br /> conoce más!
          </button>
        </div>
      </>
    );
  }
}
