//REACT
import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//IMG
import fdo_productos from "./../../assets/img/mobile/conocer-mas.webp";

//CSS
import CarrucelCSS from "../../assets/css/Mobile/carrucel.module.css";

export default class Productos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  cambiarEstado = () => {
    this.props.scroll(1);
    setTimeout(() => {
      this.props.cambiarEstado();
    }, 300);
  };

  render() {
    return (
      <>
        <h1 className={CarrucelCSS.tituloPrincipal}>Productos y servicios</h1>

        <div
          onClick={() => this.cambiarEstado()}
          style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10vw" }}>
          <img src={fdo_productos} style={{ width: "100%" }} />
          <h1 style={{ position: "absolute", color: "white", textAlign: "center", fontSize: "8vw" }}>
            Ingresa y <br /> ve los detalles…
          </h1>
        </div>
      </>
    );
  }
}
